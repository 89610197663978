export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCVOuJGywvdA9WWeD21ej11Y10VRT8MMYw",
    authDomain: "ninja-brand-prod.firebaseapp.com",
    projectId: "ninja-brand-prod",
    storageBucket: "ninja-brand-prod.appspot.com",
    messagingSenderId: "361827573193",
    appId: "1:361827573193:web:ca4d50e9d561f44a9c1c18",
    measurementId: "G-3SW3Q98G2P"
  },
  menu: {
    guest: [
      {
        id: 0,
        name: 'home',
        title: 'Начало',
        link: '/',
      },
      {
        id: 1,
        name: 'delivery',
        title: 'Доставка',
        link: '/delivery',
      },
      {
        id: 2,
        name: 'clients',
        title: 'Клиенти',
        link: '/clients',
      },
    ],
    user: [],
    admin: [
      {
        id: 999,
        title: 'Администрация',
        name: 'admin',
        link: '/admin',
      },
    ],
  },
  storage: 'https://firebasestorage.googleapis.com/v0/b/ninja-brand-prod.appspot.com/o/products%2F',
  DEFAULT_APP_CONFIGURATION: {
    preOrderTimeStep: 15,
    preOrderMissStep: 0,

    cartExpirationHours: 24,
    cartDeliveryPrice: 0,

    startHour: 12,
    startMinutes: 0,
    endHour: 22,
    endMinutes: 0,
    offDays: [1],

    orderMessage: "",

    tempOffDates: [],
    tempUnavailable: "",

    specialDates: [],
    specialDatesConfig: {},
    specialDatesDefaultStartHour: null,
    specialDatesDefaultStartMinutes: null,
    specialDatesDefaultEndHour: null,
    specialDatesDefaultEndMinutes: null,

    weekendDays: [],
    weekendDaysConfig: {},
    weekendDayDefaultStartHour: null,
    weekendDayDefaultStartMinutes: null,
    weekendDayDefaultEndHour: null,
    weekendDayDefaultEndMinutes: null,
  },
  DEFAULT_ADMIN_CONFIGURATION: {

  },
  WEEK_DICTIONARY: [
    { id: 0, localId: 7, name: 'Неделя' },
    { id: 2, localId: 2, name: 'Вторник' },
    { id: 3, localId: 3, name: 'Сряда' },
    { id: 4, localId: 4, name: 'Четвъртък' },
    { id: 5, localId: 5, name: 'Петък' },
    { id: 6, localId: 6, name: 'Събота' },
    { id: 1, localId: 1, name: 'Понеделник' },
  ]
};
